import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import { FaEye, FaEyeSlash, FaArrowRight } from "react-icons/fa";
import { Button } from "@zendeskgarden/react-buttons";
import { Field, Label, Input } from "@zendeskgarden/react-forms";
import { Span } from "@zendeskgarden/react-typography";
import styled from "styled-components";
import formPost from "../utils/formPost";
import config from "../config";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Title, Close } from "@zendeskgarden/react-notifications";
import { UserContext } from "../App";
import { Dots } from "@zendeskgarden/react-loaders";

const HomeMain = styled.main`
  text-align: center;
  .home-heading-wrap {
    display: flex;
    margin: auto;
    padding-top: 2em;
  }
  h2 {
    font-weight: 100;
    padding: 0.2em 0.5em;
    width: 50%;
    span {
      display: block;
    }
    &:first-child {
      text-align: right;
      border-right: 1px solid;
    }
    &:last-child {
      text-align: left;
    }
    @media (min-width: 800px) {
      font-size: 2.5em;
    }
    @media (max-width: 350px) {
      font-size: 1.1em;
    }
  }
  .home-input {
    font-size: 110%;
    text-align: center;
  }
`;

const CenteredBlock = styled.div`
  width: 16em;
  max-width: 65%;
  margin: auto;
  .field-wrap {
    position: relative;
    margin: 0.5em 0 1.1em;
    .move-out {
      position: absolute;
    }
  }
`;

const Home = () => {
  const { t } = useTranslation("common");
  const { api } = config;
  const history = useHistory();
  const search = useLocation().search;
  const userContext = useContext(UserContext);
  const sessionStorage = !!window ? window.sessionStorage : null;

  const emailGuidStored = sessionStorage.getItem("emailGuid");
  const codeStored = sessionStorage.getItem("code");
  const zipcodeStored = sessionStorage.getItem("zipcode");

  const emailGuidParam = new URLSearchParams(search).get("emailGuid");
  const KACodeParam = new URLSearchParams(search).get("code");
  const zipcodeParam = new URLSearchParams(search).get("zipcode");

  emailGuidParam && sessionStorage.setItem("emailGuid", emailGuidParam);
  KACodeParam && sessionStorage.setItem("code", KACodeParam);
  zipcodeParam && sessionStorage.setItem("zipcode", zipcodeParam);

  const emailGuid = !!emailGuidParam
    ? emailGuidParam
    : !!emailGuidStored
    ? emailGuidStored
    : "";
  const [code, setCode] = useState(
    !!KACodeParam ? KACodeParam : !!codeStored ? codeStored : ""
  );
  const [zipcode, setZipcode] = useState(
    !!zipcodeParam ? zipcodeParam : !!zipcodeStored ? zipcodeStored : ""
  );

  const [showCode, setShowCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const alphanumeric = /^[a-z0-9]+$/i;
  const validateCode = () =>
    code.length >= 7 &&
    code.length <= 9 &&
    alphanumeric.test(String(code).toLowerCase());
  const validateZipcode = () =>
    zipcode.length === 6 && alphanumeric.test(String(zipcode).toLowerCase());
  const validateForm = () =>
    !emailGuid ? validateCode() && validateZipcode() : validateCode();

  const handleError = () => {
    setLoading(false);
    setError(true);
  };

  const handleSuccess = (data) => {
    userContext.saveUser(data);
    sessionStorage.setItem("KAContactGuid", data.KAContactGuid);
    if (data.ResultReceived) {
      history.push("/gegevens");
    } else {
      history.push("/vragen", { KAContactGuid: data.KAContactGuid });
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setError(false);
    !!emailGuidParam
      ? sessionStorage.setItem("emailGuid", emailGuidParam)
      : sessionStorage.removeItem("emailGuid");
    sessionStorage.setItem("code", code);
    !!zipcode
      ? sessionStorage.setItem("zipcode", zipcode)
      : sessionStorage.removeItem("zipcode");
    formPost(`${api.baseUrl}${api.login}`, {
      KACode: code,
      Zipcode: !!zipcode ? zipcode : null,
      EmailGuid: !!emailGuid ? emailGuid : null,
    }).then((data) => {
      data && data.KAContactGuid ? handleSuccess(data) : handleError();
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !!validateForm()) {
      handleSubmit();
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {t("meta.siteTitle")} | {t("home.pageTitle")}
        </title>
      </Helmet>
      <HomeMain>
        <div className="home-heading-wrap">
          <h2>
            <span>{t("home.heading21.line1")}</span>
            <span>{t("home.heading21.line2")}</span>
            <span>{t("home.heading21.line3")}</span>
          </h2>
          <h2>
            <span>{t("home.heading22.line1")}</span>
            <span>{t("home.heading22.line2")}</span>
            <span>{t("home.heading22.line3")}</span>
          </h2>
        </div>
        <CenteredBlock>
          <Field>
            <Label isRegular>
              {t("home.codeLabel")}
              <Span hue="red">*</Span>
            </Label>
            <div className="field-wrap">
              <Input
                type={showCode ? "text" : "password"}
                maxLength={9}
                autoComplete="off"
                className="home-input"
                onChange={(e) =>
                  setCode(e.target.value.toUpperCase().replace(" ", ""))
                }
                placeholder={t("home.codeLabel")}
                value={code}
                onKeyPress={handleKeyPress}
              />
              <Button
                isBasic
                onClick={() => setShowCode(!showCode)}
                className="move-out"
                size="large"
              >
                {showCode ? <FaEye /> : <FaEyeSlash />}
              </Button>
            </div>
          </Field>
          {!!emailGuid || (
            <Field>
              <Label isRegular>
                {t("home.zipcodeLabel")}
                <Span hue="red">*</Span>
              </Label>
              <div className="field-wrap">
                <Input
                  maxLength={6}
                  className="home-input"
                  onChange={(e) =>
                    setZipcode(e.target.value.toUpperCase().replace(" ", ""))
                  }
                  placeholder={t("home.zipcodeLabel")}
                  value={zipcode}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </Field>
          )}
          {error && (
            <div className="alert-wrap">
              <Alert type="info">
                <Title>{t("home.errorTitle")}</Title>
                {t("home.errorText")}
                <Close
                  aria-label="Close Alert"
                  onClick={() => setError(false)}
                />
              </Alert>
            </div>
          )}
          <Button
            isPrimary
            disabled={loading || !validateForm()}
            size="large"
            data-testid="start-kijkanalyse-btn"
            onClick={handleSubmit}
            className="full-width"
          >
            {t("home.buttonText")}{" "}
            <Button.EndIcon>
              {loading ? (
                <Dots className="btn-icon" />
              ) : (
                <FaArrowRight className="btn-icon" />
              )}
            </Button.EndIcon>
          </Button>
        </CenteredBlock>
      </HomeMain>
    </React.Fragment>
  );
};

export default Home;
