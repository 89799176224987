import React, { useContext } from "react";
import styled from "styled-components";
import banner from "../img/ka-bg.jpg";
import bannerPh from "../img/ka-bg-ph.jpg";
import logo from "../img/ka-logo.png";
import { UserContext } from "../App";

const AppHeader = styled.header`
  position: relative;
  height: 20vh;
  overflow: hidden;
  @media (min-width: 1400px) {
    height: 271px;
  }
  .app-logo {
    position: absolute;
    left: 2em;
    top: 2em;
    max-width: 80%;
    max-height: 57%;
    @media (max-width: 600px) {
      margin: auto;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
`;

const Banner = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${banner}), url(${bannerPh});
  background-size: contain;
  background-position: bottom right, bottom left;
  background-repeat: no-repeat, repeat;
`;

const Header = () => {
  const userContext = useContext(UserContext);
  return (
    <AppHeader>
      <Banner img={banner} />
      {!!userContext.OpticienLogo ? (
        <img src={userContext.OpticienLogo} className="app-logo" alt="logo" />
      ) : (
        <img src={logo} className="app-logo" alt="logo" />
      )}
    </AppHeader>
  );
};

export default Header;
