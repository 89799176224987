const config = {
  api: {
    baseUrl: "https://kijkanalyse.api.llcrm.net/",
    login: "Login",
    submitAnswers: "Submit",
    submitContact: "ContactDetails",
  },
};

export default config;
