import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Tabs, TabList, Tab, TabPanel } from "@zendeskgarden/react-tabs";
import { Button } from "@zendeskgarden/react-buttons";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import Question from "./Question";
import { useHistory } from "react-router-dom";
import { Alert, Title, Close } from "@zendeskgarden/react-notifications";
import { UserContext } from "../App";
import config from "../config";
import formPost from "../utils/formPost";
import { Element as ScrollToElement, scroller as scroll } from "react-scroll";
import { Dots } from "@zendeskgarden/react-loaders";

const TabPanelWrap = styled.div`
  padding: 1em 2em 90px 2em;
  margin: auto;
  width: 900px;
  max-width: 100%;
  line-height: 1.6;
  box-sizing: border-box;
  .alert-wrap {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  @media (max-width: 800px) {
    padding-bottom: 60px;
  }
  @media (max-width: 400px) {
    p {
      font-size: 85%;
    }
  }
`;

const TabListWrap = styled.div`
  position: sticky;
  top: 0;
  .tab-wrap-inner {
    width: 800px;
    max-width: 100%;
    margin: auto;
    padding: 0 2em;
  }
  @media (max-width: 500px) {
    .tab-btn {
      font-size: 85%;
      padding-right: 1em;
      padding-left: 1em;
    }
  }
`;

const ButtonBlock = styled.nav`
  background: #e5e5e5;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1em 1.25em;
  .text-label {
    font-size: 80%;
    opacity: 0.4;
    cursor: default;
  }
  @media (max-width: 800px) {
    padding: 2px;
  }
`;

const tabArray = ["0", "1", "2", "3", "4", "5", "6"];

const Wizard = () => {
  const history = useHistory();
  const sessionStorage = !!window ? window.sessionStorage : null;
  const errorMsgResultsSaved = "Questionnaire results already saved";

  // Return home if navigated to the page without logging in
  if (
    !(!!history.location.state && !!history.location.state.KAContactGuid) &&
    !sessionStorage.KAContactGuid
  ) {
    history.push("/");
  }

  const [selectedTab, setSelectedTab] = useState("0");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const goToNextTab = () => {
    scroll.scrollTo("tabHeader", {
      smooth: true,
      duration: 500,
    });
    switch (selectedTab) {
      case "5":
        error === errorMsgResultsSaved
          ? history.push("/gegevens")
          : handleSubmit();
        break;
      case "6":
        history.push("/gegevens");
        break;
      default:
        setSelectedTab("" + (+selectedTab + 1));
        break;
    }
  };

  const goToPrevTab = () => {
    scroll.scrollTo("tabHeader", {
      smooth: true,
      duration: 500,
    });
    setSelectedTab("" + (+selectedTab - 1));
  };
  const { t } = useTranslation("common");
  const { api } = config;
  const userContext = useContext(UserContext);

  // Tab 1
  const [q1SelectedItem, setQ1SelectedItem] = useState("");
  const [q2aCheckbox, setQ2aCheckbox] = useState("");
  const [q2bCheckbox, setQ2bCheckbox] = useState("");
  const [q2cCheckbox, setQ2cCheckbox] = useState("");
  const [q2dCheckbox, setQ2dCheckbox] = useState("");
  const [q2eCheckbox, setQ2eCheckbox] = useState("");
  const [q2fCheckbox, setQ2fCheckbox] = useState("");
  const [q2gCheckbox, setQ2gCheckbox] = useState("");
  const [q2hCheckbox, setQ2hCheckbox] = useState("");
  const [q2iCheckbox, setQ2iCheckbox] = useState("");
  const [q3SelectedItem, setQ3SelectedItem] = useState("");
  const [q4SelectedItem, setQ4SelectedItem] = useState("");

  // Tab 2
  const [q5SelectedItem, setQ5SelectedItem] = useState("");
  const [q5aCheckbox, setQ5aCheckbox] = useState("");
  const [q5bCheckbox, setQ5bCheckbox] = useState("");
  const [q5cCheckbox, setQ5cCheckbox] = useState("");
  const [q6SelectedItem, setQ6SelectedItem] = useState("");
  const [q7SelectedItem, setQ7SelectedItem] = useState("");
  const [q8SelectedItem, setQ8SelectedItem] = useState("");

  // Tab 3
  const [q9SelectedItem, setQ9SelectedItem] = useState("");
  const [q10SelectedItem, setQ10SelectedItem] = useState("");
  const [q11SelectedItem, setQ11SelectedItem] = useState("");
  const [q12SelectedItem, setQ12SelectedItem] = useState("");
  const [q13SelectedItem, setQ13SelectedItem] = useState("");
  const [q14SelectedItem, setQ14SelectedItem] = useState("");

  // Tab 4
  const [q15aCheckbox, setQ15aCheckbox] = useState("");
  const [q15bCheckbox, setQ15bCheckbox] = useState("");
  const [q15cCheckbox, setQ15cCheckbox] = useState("");
  const [q15dCheckbox, setQ15dCheckbox] = useState("");
  const [q16SelectedItem, setQ16SelectedItem] = useState("");
  const [q17aSelectedItem, setQ17aSelectedItem] = useState("");
  const [q17bSelectedItem, setQ17bSelectedItem] = useState("");
  const [q18aCheckbox, setQ18aCheckbox] = useState("");
  const [q18bCheckbox, setQ18bCheckbox] = useState("");
  const [q18cCheckbox, setQ18cCheckbox] = useState("");
  const [q18dCheckbox, setQ18dCheckbox] = useState("");
  const [q19aSelectedItem, setQ19aSelectedItem] = useState("");
  const [q19bSelectedItem, setQ19bSelectedItem] = useState("");

  // Tab 5
  const [q20aCheckbox, setQ20aCheckbox] = useState("");
  const [q20bCheckbox, setQ20bCheckbox] = useState("");
  const [q20cCheckbox, setQ20cCheckbox] = useState("");
  const [q20dCheckbox, setQ20dCheckbox] = useState("");
  const [q20eCheckbox, setQ20eCheckbox] = useState("");
  const [q20fCheckbox, setQ20fCheckbox] = useState("");
  const [q20gCheckbox, setQ20gCheckbox] = useState("");
  const [q21aSelectedItem, setQ21aSelectedItem] = useState("");
  const [q21bSelectedItem, setQ21bSelectedItem] = useState("");

  const joinValuesIntoString = (array) =>
    array.filter((item) => !!item === true).join(", ");

  const validateTab = (activeTab) => {
    switch (activeTab) {
      case "1":
        return q1SelectedItem !== t(`form.q1.answers1.a1`)
          ? !!q1SelectedItem &&
              !!joinValuesIntoString([
                q2aCheckbox,
                q2bCheckbox,
                q2cCheckbox,
                q2dCheckbox,
                q2eCheckbox,
                q2fCheckbox,
                q2gCheckbox,
                q2hCheckbox,
                q2iCheckbox,
              ]) &&
              !!q3SelectedItem &&
              !!q4SelectedItem
          : !!q1SelectedItem;
      case "2":
        return q5SelectedItem !== t(`form.q5.answers1.a1`)
          ? !!q5SelectedItem &&
              !!joinValuesIntoString([q5aCheckbox, q5bCheckbox, q5cCheckbox]) &&
              !!q6SelectedItem &&
              !!q7SelectedItem &&
              !!q8SelectedItem
          : !!q5SelectedItem &&
              !!q6SelectedItem &&
              !!q7SelectedItem &&
              !!q8SelectedItem;
      case "3":
        return (
          !!q9SelectedItem &&
          !!q10SelectedItem &&
          !!q11SelectedItem &&
          !!q12SelectedItem &&
          !!q13SelectedItem &&
          !!q14SelectedItem
        );
      case "4":
        return (
          !!joinValuesIntoString([
            q15aCheckbox,
            q15bCheckbox,
            q15cCheckbox,
            q15dCheckbox,
          ]) &&
          !!q16SelectedItem &&
          !!q17aSelectedItem &&
          !!q17bSelectedItem &&
          !!joinValuesIntoString([
            q18aCheckbox,
            q18bCheckbox,
            q18cCheckbox,
            q18dCheckbox,
          ]) &&
          !!q19aSelectedItem &&
          !!q19bSelectedItem
        );
      case "5":
        return (
          !!joinValuesIntoString([
            q20aCheckbox,
            q20bCheckbox,
            q20cCheckbox,
            q20dCheckbox,
            q20eCheckbox,
            q20fCheckbox,
            q20gCheckbox,
          ]) &&
          !!q21aSelectedItem &&
          !!q21bSelectedItem
        );
      default:
        return true;
    }
  };

  const handleError = (error) => {
    setLoading(false);
    setError(!!error.Error ? error.Error : "Unknown Error");
  };

  const handleSuccess = (data) => {
    setLoading(false);
    userContext.saveUser({ ...userContext, ...data });
    setSelectedTab("" + (+selectedTab + 1));
  };

  const handleSubmit = () => {
    setLoading(true);
    setError("");
    formPost(`${api.baseUrl}${api.submitAnswers}`, {
      KAContactGuid: userContext.KAContactGuid,
      UURSPORTEN: q1SelectedItem,
      SPORTEN: joinValuesIntoString([
        q2aCheckbox,
        q2bCheckbox,
        q2cCheckbox,
        q2dCheckbox,
        q2eCheckbox,
        q2fCheckbox,
        q2gCheckbox,
        q2hCheckbox,
        q2iCheckbox,
      ]),
      BRILBIJSPORT: q3SelectedItem,
      LASTVANBRILSPORT: q4SelectedItem,
      UURBEELDSCHERM: q5SelectedItem,
      TYPEBEELDSCHERM: joinValuesIntoString([
        q5aCheckbox,
        q5bCheckbox,
        q5cCheckbox,
      ]),
      AFSTANDSCHERP: q6SelectedItem,
      GOEDZIEN: q7SelectedItem,
      LASTVANSPIEGELING: q8SelectedItem,
      BRILVOORWERK: q9SelectedItem,
      VERMOEIDEOGEN: q10SelectedItem,
      BRANDERIGEOGEN: q11SelectedItem,
      MOEILIJKFOCUSSEN: q12SelectedItem,
      NEKSCHOUDERPIJN: q13SelectedItem,
      HOOFDPIJN: q14SelectedItem,
      VERVOERSMIDDEL: joinValuesIntoString([
        q15aCheckbox,
        q15bCheckbox,
        q15cCheckbox,
        q15dCheckbox,
      ]),
      UURREIZEN: q16SelectedItem,
      RIJDENINDONKER: q17aSelectedItem,
      DONKERHINDERLIJK: q17bSelectedItem,
      IDEALEVAKANTIE: joinValuesIntoString([
        q18aCheckbox,
        q18bCheckbox,
        q18cCheckbox,
        q18dCheckbox,
      ]),
      LICHTGEVOELIG: q19aSelectedItem,
      GOEDZIENDONKER: q19bSelectedItem,
      IDEALEZONNEBRIL: joinValuesIntoString([
        q20aCheckbox,
        q20bCheckbox,
        q20cCheckbox,
        q20dCheckbox,
        q20eCheckbox,
        q20fCheckbox,
        q20gCheckbox,
      ]),
      LEEFTIJDZONNEBRIL: q21aSelectedItem,
      ZONNEBRILSTERKTE: q21bSelectedItem,
    }).then((data) =>
      data && data.Aanbieding ? handleSuccess(data) : handleError(data)
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {t("meta.siteTitle")} | {t("wizard.pageTitle")}
        </title>
      </Helmet>
      <Tabs selectedItem={selectedTab} onChange={setSelectedTab}>
        <ScrollToElement name="tabHeader">
          <TabListWrap>
            <TabList>
              <div className="tab-wrap-inner">
                {tabArray.map((item) =>
                  (["2", "3", "4", "5"].includes(item) &&
                    item !== selectedTab &&
                    item !== "2") ||
                  (item === "2" &&
                    ["3", "4", "5"].includes(selectedTab)) ? null : (
                    <Tab
                      item={item}
                      key={item}
                      className="tab-btn"
                      disabled={item !== selectedTab}
                    >
                      {t(`wizard.tabs.tab${item}`)}
                    </Tab>
                  )
                )}
              </div>
            </TabList>
          </TabListWrap>
        </ScrollToElement>
        <TabPanelWrap>
          <TabPanel item="0">
            <h1>{t("start.heading")}</h1>
            <p>{t("start.text1")}</p>
            <p>{t("start.text2")}</p>
          </TabPanel>
          <TabPanel item="1">
            <Question
              id="q1"
              value1={q1SelectedItem}
              setValue1={setQ1SelectedItem}
            />
            <Question
              id="q2"
              valueArray1={[
                q2aCheckbox,
                q2bCheckbox,
                q2cCheckbox,
                q2dCheckbox,
                q2eCheckbox,
                q2fCheckbox,
                q2gCheckbox,
                q2hCheckbox,
                q2iCheckbox,
              ]}
              setValueArray1={[
                setQ2aCheckbox,
                setQ2bCheckbox,
                setQ2cCheckbox,
                setQ2dCheckbox,
                setQ2eCheckbox,
                setQ2fCheckbox,
                setQ2gCheckbox,
                setQ2hCheckbox,
                setQ2iCheckbox,
              ]}
              isNotRequired={q1SelectedItem === t(`form.q1.answers1.a1`)}
            />
            <Question
              id="q3"
              value1={q3SelectedItem}
              setValue1={setQ3SelectedItem}
              isNotRequired={q1SelectedItem === t(`form.q1.answers1.a1`)}
            />
            <Question
              id="q4"
              value1={q4SelectedItem}
              setValue1={setQ4SelectedItem}
              isNotRequired={q1SelectedItem === t(`form.q1.answers1.a1`)}
            />
          </TabPanel>
          <TabPanel item="2">
            <Question
              id="q5"
              value1={q5SelectedItem}
              setValue1={setQ5SelectedItem}
              valueArray2={[q5aCheckbox, q5bCheckbox, q5cCheckbox]}
              setValueArray2={[setQ5aCheckbox, setQ5bCheckbox, setQ5cCheckbox]}
              part2IsNotRequired={q5SelectedItem === t(`form.q5.answers1.a1`)}
            />
            <Question
              id="q6"
              value1={q6SelectedItem}
              setValue1={setQ6SelectedItem}
            />
            <Question
              id="q7"
              value1={q7SelectedItem}
              setValue1={setQ7SelectedItem}
            />
            <Question
              id="q8"
              value1={q8SelectedItem}
              setValue1={setQ8SelectedItem}
              isLast
            />
          </TabPanel>
          <TabPanel item="3">
            <Question
              id="q9"
              value1={q9SelectedItem}
              setValue1={setQ9SelectedItem}
            />
            <Question
              id="q10"
              value1={q10SelectedItem}
              setValue1={setQ10SelectedItem}
            />
            <Question
              id="q11"
              value1={q11SelectedItem}
              setValue1={setQ11SelectedItem}
            />
            <Question
              id="q12"
              value1={q12SelectedItem}
              setValue1={setQ12SelectedItem}
            />
            <Question
              id="q13"
              value1={q13SelectedItem}
              setValue1={setQ13SelectedItem}
            />
            <Question
              id="q14"
              value1={q14SelectedItem}
              setValue1={setQ14SelectedItem}
              isLast
            />
          </TabPanel>
          <TabPanel item="4">
            <Question
              id="q15"
              valueArray1={[
                q15aCheckbox,
                q15bCheckbox,
                q15cCheckbox,
                q15dCheckbox,
              ]}
              setValueArray1={[
                setQ15aCheckbox,
                setQ15bCheckbox,
                setQ15cCheckbox,
                setQ15dCheckbox,
              ]}
            />
            <Question
              id="q16"
              value1={q16SelectedItem}
              setValue1={setQ16SelectedItem}
            />
            <Question
              id="q17"
              value1={q17aSelectedItem}
              setValue1={setQ17aSelectedItem}
              value2={q17bSelectedItem}
              setValue2={setQ17bSelectedItem}
            />
            <Question
              id="q18"
              valueArray1={[
                q18aCheckbox,
                q18bCheckbox,
                q18cCheckbox,
                q18dCheckbox,
              ]}
              setValueArray1={[
                setQ18aCheckbox,
                setQ18bCheckbox,
                setQ18cCheckbox,
                setQ18dCheckbox,
              ]}
            />
            <Question
              id="q19"
              value1={q19aSelectedItem}
              setValue1={setQ19aSelectedItem}
              value2={q19bSelectedItem}
              setValue2={setQ19bSelectedItem}
              isLast
            />
          </TabPanel>
          <TabPanel item="5">
            <Question
              id="q20"
              valueArray1={[
                q20aCheckbox,
                q20bCheckbox,
                q20cCheckbox,
                q20dCheckbox,
                q20eCheckbox,
                q20fCheckbox,
                q20gCheckbox,
              ]}
              setValueArray1={[
                setQ20aCheckbox,
                setQ20bCheckbox,
                setQ20cCheckbox,
                setQ20dCheckbox,
                setQ20eCheckbox,
                setQ20fCheckbox,
                setQ20gCheckbox,
              ]}
            />
            <Question
              id="q21"
              value1={q21aSelectedItem}
              setValue1={setQ21aSelectedItem}
              value2={q21bSelectedItem}
              setValue2={setQ21bSelectedItem}
              isLast
            />
            {!!error && (
              <div className="alert-wrap">
                <Alert type="info">
                  <Title>
                    {error === errorMsgResultsSaved
                      ? t("wizard.errorQuestionnaireSubmittedTitle")
                      : t("wizard.errorTitle")}
                  </Title>
                  {error === errorMsgResultsSaved
                    ? t("wizard.errorQuestionnaireSubmittedText")
                    : t("wizard.errorText")}
                  <Close
                    aria-label="Close Alert"
                    onClick={() => setError(false)}
                  />
                </Alert>
              </div>
            )}
          </TabPanel>
          <TabPanel item="6">
            <h1>{t("wizard.resultTitle")}</h1>
            {!!userContext.AdviesBuiten && <p>{userContext.AdviesBuiten}</p>}
            {!!userContext.AdviesSport && <p>{userContext.AdviesSport}</p>}
            {!!userContext.AdviesWerk && <p>{userContext.AdviesWerk}</p>}
            {!!userContext.AdviesZon && <p>{userContext.AdviesZon}</p>}
          </TabPanel>
        </TabPanelWrap>
      </Tabs>

      <ButtonBlock>
        <Button
          isBasic
          disabled={tabArray.indexOf(selectedTab) === 0}
          onClick={goToPrevTab}
        >
          <Button.StartIcon>
            <BiChevronLeft />
          </Button.StartIcon>
          {t("wizard.previousButton")}
        </Button>
        <span className="text-label">
          {tabArray.indexOf(selectedTab) < 6 &&
            tabArray.indexOf(selectedTab) +
              1 +
              " " +
              t("wizard.outOf") +
              " " +
              (tabArray.length - 1)}
        </span>
        <Button
          isBasic
          disabled={
            selectedTab === tabArray[tabArray.length] ||
            loading ||
            !validateTab(selectedTab)
          }
          onClick={goToNextTab}
        >
          {selectedTab === tabArray[tabArray.length - 1]
            ? t("wizard.couponButton")
            : t("wizard.nextButton")}
          {loading ? <Dots className="btn-icon" /> : <BiChevronRight />}
        </Button>
      </ButtonBlock>
    </React.Fragment>
  );
};

export default Wizard;
