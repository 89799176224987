import { Col, Grid, Row } from "@zendeskgarden/react-grid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { UserContext } from "../App";

const SPageWrap = styled.div`
  margin: auto;
  width: 900px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 3em;
  max-width: 100%;
  line-height: 1.6;
  @media (max-width: 600px) {
    padding: 2em 1em 1em;
  }
  h4 {
    margin-bottom: 0;
  }
`;

const ThankYouPage = () => {
  const { t } = useTranslation("common");
  const userContext = useContext(UserContext);

  const website = !!userContext.OpticienUrl
    ? userContext.OpticienUrl
    : t("thankYouPage.contact.website");
  const email = !!userContext.OpticienEmail
    ? userContext.OpticienEmail
    : t("thankYouPage.contact.email");
  const phone = !!userContext.OpticienTelefoon
    ? userContext.OpticienTelefoon
    : t("thankYouPage.contact.phone");
  const company = !!userContext.OpticienNaam
    ? userContext.OpticienNaam
    : t("thankYouPage.signature.company");
  const streetAddress = !!userContext.OpticienAdres
    ? userContext.OpticienAdres
    : t("thankYouPage.signature.streetAddress");
  const city = !!userContext.OpticienPlaats
    ? userContext.OpticienPlaats
    : t("thankYouPage.signature.city");

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {t("meta.siteTitle")} | {t("thankYouPage.pageTitle")}
        </title>
      </Helmet>
      <SPageWrap>
        <Grid gutters="xl">
          <Row>
            <Col>
              <h1>{t("thankYouPage.heading")}</h1>
            </Col>
          </Row>
          <Row>
            <Col md>
              <p>{t("thankYouPage.thankYouMessage")}</p>
            </Col>
            <Col md>
              <h4>{t("thankYouPage.sidebarHeading")}</h4>
              <p>{t("thankYouPage.sidebarText")}</p>
              <p>
                <a href={website} target="_blank" rel="noreferrer">
                  {website}
                </a>
                <br />
                <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
                  {email}
                </a>
                <br />
                {phone}
              </p>
              <p>{t("thankYouPage.signature.text")}</p>
              <p>
                <strong>{company}</strong>
                <br />
                {streetAddress}
                <br />
                {city}
              </p>
            </Col>
          </Row>
        </Grid>
      </SPageWrap>
    </React.Fragment>
  );
};

export default ThankYouPage;
