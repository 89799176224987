import React, { useState, createContext } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming";
import Home from "./components/Home";
import Wizard from "./components/Wizard";
import ContactForm from "./components/ContactForm";
import ThankYouPage from "./components/ThankYouPage";
import Header from "./components/Header";
import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Error from "./components/Error";

const defaultContext = {
  KAContactGuid: "",
  Email: "",
  OpticienAdres: "",
  OpticienEmail: "",
  OpticienLogo: "",
  OpticienNaam: "",
  OpticienPlaats: "",
  OpticienTelefoon: "",
  OpticienUrl: "",
  Aanbieding: "",
  AdviesBuiten: "",
  AdviesSport: "",
  AdviesWerk: "",
  AdviesZon: "",
  saveUser: () => {},
};
export const UserContext = createContext(defaultContext);

const MainSection = styled.section`
  box-shadow: 0px -12px 10px rgb(0 0 0 / 10%);
  z-index: 1;
  position: relative;
`;

const App = () => {
  const { t } = useTranslation("common");

  const [user, setUser] = useState({});
  const saveUser = (userData) => {
    setUser(userData);
  };

  return (
    <HelmetProvider>
      <ThemeProvider
        theme={{
          ...DEFAULT_THEME,
          colors: {
            ...DEFAULT_THEME.colors,
            primaryHue: "#333",
            dangerHue: "#39b54a",
          },
        }}
      >
        <UserContext.Provider value={{ ...defaultContext, ...user, saveUser }}>
          <React.Fragment>
            <Helmet>
              <title>{t("meta.siteTitle")}</title>
            </Helmet>
            <Header />
            <MainSection>
              <Router>
                <Switch>
                  <Route path="/vragen">
                    <Wizard />
                  </Route>
                  <Route path="/gegevens">
                    <ContactForm />
                  </Route>
                  <Route path="/dank">
                    <ThankYouPage />
                  </Route>
                  <Route path="/" exact>
                    <Home />
                  </Route>
                  <Route path="*">
                    <Error />
                  </Route>
                </Switch>
              </Router>
            </MainSection>
          </React.Fragment>
        </UserContext.Provider>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
