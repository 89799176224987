import axios from "axios";

const formPost = async (url, data) => {
  return axios(url, {
    method: "post",
    data,
  })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        return error.response.data;
      }
    });
};

export default formPost;
