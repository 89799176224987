import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Field, Label, Input, Checkbox } from "@zendeskgarden/react-forms";
import { Row, Col } from "@zendeskgarden/react-grid";
import { Button } from "@zendeskgarden/react-buttons";
import { useHistory } from "react-router-dom";
import formPost from "../utils/formPost";
import config from "../config";
import { UserContext } from "../App";
import { Alert, Title, Close } from "@zendeskgarden/react-notifications";
import { Dots } from "@zendeskgarden/react-loaders";
import { BiChevronRight } from "react-icons/bi";

const SContactForm = styled.div`
  margin: auto;
  width: 620px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 3em;
  max-width: 100%;
  line-height: 1.6;
  .btn-wrap {
    button {
      min-width: 12em;
    }
  }
`;

const FieldWrap = styled.div`
  margin-bottom: 1em;
  .radio-inline {
    margin-left: 0.75em;
    display: inline-block;
  }
`;

const ContactForm = () => {
  const { t } = useTranslation("common");
  const { api } = config;
  const history = useHistory();
  const userContext = useContext(UserContext);
  const sessionStorage = !!window ? window.sessionStorage : null;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [email, setEmail] = useState(userContext.Email || "");

  const company = !!userContext.OpticienNaam
    ? userContext.OpticienNaam
    : t("thankYouPage.signature.company");

  const handleError = (data) => {
    setLoading(false);
    setError(true);
  };

  const handleSuccess = () => {
    history.push("/dank");
  };

  const handleSubmit = () => {
    setLoading(true);
    setError(false);
    formPost(`${api.baseUrl}${api.submitContact}`, {
      KAContactGuid: sessionStorage.KAContactGuid,
      Email: email,
    }).then((data) =>
      data && data.Success === 1 ? handleSuccess() : handleError(data)
    );
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && re.test(String(email).toLowerCase());
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !!validateEmail(email)) {
      handleSubmit();
    }
  };

  // Return home if navigated to the page without logging in
  if (!sessionStorage.KAContactGuid) {
    history.push("/");
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {t("meta.siteTitle")} | {t("contactForm.pageTitle")}
        </title>
      </Helmet>
      <SContactForm>
        <h1>{t("contactForm.pageHeader")}</h1>
        <p>
          {!!userContext.Aanbieding
            ? userContext.Aanbieding
            : t("contactForm.pageText")}
        </p>

        <Row>
          <Col>
            <FieldWrap>
              <Field>
                <Label isRegular>{t("contactForm.email")}</Label>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("contactForm.email")}
                  onKeyPress={handleKeyPress}
                />
              </Field>
            </FieldWrap>
          </Col>
        </Row>
        <FieldWrap>
          <Field>
            <Checkbox
              checked={newsletter}
              onChange={() => setNewsletter(!newsletter)}
            >
              <Label isRegular>
                {t("contactForm.newsletterStart")} {company}{" "}
                {t("contactForm.newsletterEnd")}
              </Label>
            </Checkbox>
          </Field>
        </FieldWrap>
        {error && (
          <div className="alert-wrap">
            <Alert type="info">
              <Title>{t("contactForm.errorTitle")}</Title>
              {t("contactForm.errorText")}
              <Close aria-label="Close Alert" onClick={() => setError(false)} />
            </Alert>
          </div>
        )}
        <div className="btn-wrap">
          <Button
            isPrimary
            size="large"
            onClick={handleSubmit}
            disabled={loading || !validateEmail(email) || !newsletter}
          >
            {t("contactForm.sendButton")}
            {loading ? (
              <Dots className="btn-icon" />
            ) : (
              <BiChevronRight className="btn-icon" />
            )}
          </Button>
        </div>
      </SContactForm>
    </React.Fragment>
  );
};

export default ContactForm;
