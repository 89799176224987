import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SErrorPage = styled.div`
  margin: auto;
  width: 620px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 3em;
  max-width: 100%;
  line-height: 1.6;
`;

const Error = () => {
  const { t } = useTranslation("common");

  return (
    <SErrorPage>
      <h1>{t("error.errorPageTitle")}</h1>
      <p>
        {t("error.errorPageText")}{" "}
        <Link to="/">{t("error.errorPageLinkText")}</Link>
      </p>
    </SErrorPage>
  );
};

export default Error;
