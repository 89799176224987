import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  Dropdown,
  Field as DropdownField,
  Menu,
  Item,
  Select,
} from "@zendeskgarden/react-dropdowns";
import { Field, Label, Checkbox } from "@zendeskgarden/react-forms";
import { Button } from "@zendeskgarden/react-buttons";
import formContentNL from "../translations/nl/common.json";

const SField = styled.div`
  padding-left: 2em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 2.5em;
  position: relative;
  transition: opacity 0.4s;
  &.isNotRequired {
    label,
    .select-wrap > div:first-child,
    .validation-success-icon {
      opacity: 0.5;
    }
  }
  .partIsNotRequired {
    opacity: 0.5;
  }
  &:not(:last-child) {
    margin-bottom: 2em;
    border-bottom: 1px solid #ddd;
    padding-bottom: 2em;
  }
  .inline-btn-wrap {
    display: inline-block;
    margin: 0.5em 0.75em;
    @media (max-width: 500px) {
      display: flex;
      margin: 8px -0.15em;
      width: 100%;
      flex-wrap: wrap;
      &.select-wrap {
        display: block;
      }
      button {
        flex-grow: 1;
      }
    }
    &.select-wrap {
      min-width: 15em;
      @media (max-width: 500px) {
        min-width: 13em;
      }
    }
    button {
      margin: 0.15em 0.15em;
    }
  }
  .question-label {
    margin-top: 2px;
    @media (max-width: 500px) {
      width: 100%;
      display: block;
    }
  }
  .checkbox-group {
    display: inline-flex;
    flex-wrap: wrap;
    padding-top: 8px;
    width: 100%;
    &.group-of-3 {
      .checkbox-inline {
        @media (min-width: 600px) {
          width: 25%;
        }
      }
    }
  }
  .checkbox-inline {
    margin-left: 0.75em;
    width: 45%;
    display: flex;
    height: 2.2em;
    align-items: center;
    @media (max-width: 620px) {
      width: 100%;
      display: flex;
      height: 2.2em;
      align-items: center;
    }
  }
`;

const SCheckmarkWrap = styled.span`
  margin-left: -2em;
  float: left;
  svg {
    margin-right: 0.5em;
    font-size: 1.25em;
    color: #39b54a;
    vertical-align: -0.2em;
    transition: opacity 0.2s;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  }
`;

const Question = ({
  id,
  value1,
  setValue1,
  valueArray1,
  setValueArray1,
  value2,
  setValue2,
  valueArray2,
  setValueArray2,
  isNotRequired,
  part2IsNotRequired,
}) => {
  const { t } = useTranslation("common");
  const questionContent = formContentNL.form[id];
  const keys = Object.keys(questionContent);

  const hasStart = keys.includes("start");
  const hasMiddle = keys.includes("middle");
  const hasEnd = keys.includes("end");

  const answerKeys1 = keys.includes("answers1")
    ? Object.keys(questionContent.answers1)
    : [];
  const answerKeys2 = keys.includes("answers2")
    ? Object.keys(questionContent.answers2)
    : [];

  const validateQuestion = () =>
    (!valueArray1 &&
      !valueArray2 &&
      typeof value2 === "undefined" &&
      !!value1) || // value1 is provided
    (typeof value2 === "undefined" &&
      !!valueArray1 &&
      valueArray1.some((item) => !!item === true)) || // valueArray1 is provided
    (!!value1 && !!value2) || // value1 and value2 are provided
    (!!valueArray1 &&
      valueArray1.some((item) => !!item === true) &&
      !!value2) || // valueArray1 and value2 are provided
    (!!value1 && !!valueArray2 && valueArray2.some((item) => !!item === true)); // value1 and valueArray2 are provided

  return (
    <SField className={isNotRequired && "isNotRequired"}>
      <SCheckmarkWrap
        isVisible={validateQuestion()}
        className="validation-success-icon"
      >
        <AiFillCheckCircle />
      </SCheckmarkWrap>
      {hasStart && (
        <Label isRegular className="question-label question-start-label">
          {t(`form.${id}.start`)}
        </Label>
      )}

      {valueArray1 && valueArray1.length > 0 ? (
        // Multiple options selectable
        <span
          className={`checkbox-group ${
            valueArray1.length % 3 === 0 && "group-of-3"
          }`}
        >
          {answerKeys1.map((item, i) => (
            <Field className="checkbox-inline" key={item}>
              <Checkbox
                checked={!!valueArray1[i]}
                onChange={() =>
                  !valueArray1[i]
                    ? setValueArray1[i](t(`form.${id}.answers1.${item}`))
                    : setValueArray1[i]("")
                }
              >
                <Label isRegular>{t(`form.${id}.answers1.${item}`)}</Label>
              </Checkbox>
            </Field>
          ))}
        </span>
      ) : (
        // Single option selectable
        answerKeys1.length > 0 &&
        (answerKeys1.length > 4 ? (
          // Use select
          <span className="inline-btn-wrap select-wrap">
            <Dropdown selectedItem={value1} onSelect={setValue1}>
              <DropdownField>
                <Select>{value1 ? value1 : t("wizard.select")}</Select>
              </DropdownField>
              <Menu>
                {answerKeys1.map((item) => (
                  <Item key={item} value={t(`form.${id}.answers1.${item}`)}>
                    {t(`form.${id}.answers1.${item}`)}
                  </Item>
                ))}
              </Menu>
            </Dropdown>
          </span>
        ) : (
          // Use buttons
          <span className="inline-btn-wrap">
            {answerKeys1.map((item) => (
              <Button
                key={item}
                isPrimary={value1 === t(`form.${id}.answers1.${item}`)}
                onClick={() => setValue1(t(`form.${id}.answers1.${item}`))}
              >
                {t(`form.${id}.answers1.${item}`)}
              </Button>
            ))}
          </span>
        ))
      )}

      {hasMiddle && (
        <Label isRegular className="question-label">
          {t(`form.${id}.middle`)}
        </Label>
      )}

      {valueArray2 && valueArray2.length > 0 ? (
        // Multiple options selectable
        <span
          className={`checkbox-group ${
            valueArray2.length % 3 === 0 && "group-of-3"
          } ${part2IsNotRequired && "partIsNotRequired"}`}
        >
          {answerKeys2.map((item, i) => (
            <Field className="checkbox-inline" key={item}>
              <Checkbox
                checked={!!valueArray2[i]}
                onChange={() =>
                  !valueArray2[i]
                    ? setValueArray2[i](t(`form.${id}.answers2.${item}`))
                    : setValueArray2[i]("")
                }
              >
                <Label isRegular>{t(`form.${id}.answers2.${item}`)}</Label>
              </Checkbox>
            </Field>
          ))}
        </span>
      ) : (
        // Single option selectable
        answerKeys2.length > 0 &&
        (answerKeys2.length > 4 ? (
          // Use select
          <span className="inline-btn-wrap select-wrap">
            <Dropdown
              selectedItem={value2}
              onSelect={setValue2}
              downshiftProps={(item) => item}
            >
              <DropdownField>
                <Select>{value2 ? value2 : t("wizard.select")}</Select>
              </DropdownField>
              <Menu>
                {answerKeys2.map((item) => (
                  <Item key={item} value={t(`form.${id}.answers2.${item}`)}>
                    {t(`form.${id}.answers2.${item}`)}
                  </Item>
                ))}
              </Menu>
            </Dropdown>
          </span>
        ) : (
          // Use buttons
          <span className="inline-btn-wrap">
            {answerKeys2.map((item2) => (
              <Button
                key={item2}
                isPrimary={value2 === t(`form.${id}.answers2.${item2}`)}
                onClick={() => setValue2(t(`form.${id}.answers2.${item2}`))}
              >
                {t(`form.${id}.answers2.${item2}`)}
              </Button>
            ))}
          </span>
        ))
      )}

      {hasEnd && (
        <Label isRegular className="question-label">
          {t(`form.${id}.end`)}
        </Label>
      )}
    </SField>
  );
};

export default Question;
